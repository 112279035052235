import React from 'react'
import PropTypes from 'prop-types'

import Carousel from 'components/carousel'
import Leader from 'components/leader'
import Button from 'components/button'
import ContentfulRichText from 'components/contentful-rich-text'

import ProxyLove from 'assets/svg/proxy-love.svg'
import Item from './Item'
import s from './ProxyLoveBanner.scss'

const ProxyLoveBanner = ({ testimonials, body, button, buttonActive }) => {
  if(testimonials) return (
    <div className={s.testimonials}>
      <Leader
        customHeading={<div className={s.logo}><ProxyLove /></div>}
        text={<ContentfulRichText content={body?.json}/>}
        className={s.leader}
      >
        {!!buttonActive && <Button color="transparent" to="/love">{button}</Button>}
      </Leader>
      <Carousel narrow>
        {testimonials.map(x => (
          <Item
            key={x?.authorName}
            quote={x?.quote?.text}
            cite={x?.authorName}
            image={x?.authorThumbnail}
          />
        ))}
      </Carousel>
    </div>
  )
}

ProxyLoveBanner.propTypes = {
  text: PropTypes.string,
  buttonText: PropTypes.string,
  testimonials: PropTypes.arrayOf(
    PropTypes.shape({
      quote: PropTypes.object,
      author: PropTypes.string,
      image: PropTypes.shape({
        src: PropTypes.string,
        alt: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
      }),
    })
  ),
}

ProxyLoveBanner.defaultProps = {
  heading: null,
  text: null,
  testimonials: [],
}

export default ProxyLoveBanner
