import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Button from 'components/button';
import Image from 'components/image';

import s from './SolutionCard.scss';

export default class SolutionCard extends PureComponent {

  static propTypes = {
    heading: PropTypes.string,
    link: PropTypes.string,
    text: PropTypes.string,
    image: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
    }),
  }

  static defaultProps = {
    heading: '',
    link: '',
    text: '',
    image: null,
  }

  render() {
    const { heading, link, text, image } = this.props;

    const IMAGE_WIDTH = 600;

    return (
      <div className={s.solutionCard}>
        {image &&
          <div>
            <Image
              src={`${image.src}?w=${IMAGE_WIDTH}`}
              src2x={`${image.src}?w=${IMAGE_WIDTH * 2}`}
              alt={image.alt || ''}
              width={image.width}
              height={image.height}
              transition="scale"
            />
          </div>
        }
        <div className={s.solutionCard__content}>
          <h3 className={s.solutionCard__heading}>{heading}</h3>
          <p className={s.solutionCard__text}>{text}</p>
          <div className={s.solutionCard__button}>
            <Button arrow to={link} />
          </div>
        </div>
      </div>
    );
  }
}
