import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ViewportEnter from 'components/viewport-enter';

import ImageColumnVariant from 'components/image-column-variant';

import s from './IdentitySignal.scss';

export default class IdentitySignal extends Component {

  state = {
    active: false,
  }

  static propTypes = {
    heading: PropTypes.string,
    text: PropTypes.string,
    image: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
    }),
    ctaText: PropTypes.string,
    ctaUrl: PropTypes.string,
  }

  static defaultProps = {
    heading: '',
    text: '',
    image: null,
    ctaText: 'Read more',
    ctaUrl: '',
  }

  onEnter = () => {
    this.setState({
      active: true,
    });
  }

  render() {
    const { heading, text, image, ctaText, ctaUrl } = this.props;
    const { active } = this.state;

    return (
      <ViewportEnter onEnter={this.onEnter} threshold={0.4}>
        <div className={s('identitySignal', { active })}>
          <div className={s.identitySignal__backdrop} />
          <div className={s.identitySignal__inner}>
            <ImageColumnVariant
              image={image}
              heading={heading}
              text={text}
              ctas={[{ text: ctaText, url: ctaUrl }]}
            />
          </div>
        </div>
      </ViewportEnter>
    );
  }
}
