import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Leader from 'components/leader'
import Segment, { Container, Row } from 'components/layout'
import SolutionCard from './SolutionCard'

import s from './SolutionsList.scss'

export default class SolutionsList extends Component {
  static propTypes = {
    heading: PropTypes.string,
    text: PropTypes.string,
    solutions: PropTypes.arrayOf(
      PropTypes.shape({
        heading: PropTypes.string,
        link: PropTypes.string,
        text: PropTypes.string,
        image: PropTypes.shape({
          src: PropTypes.string,
          alt: PropTypes.string,
          width: PropTypes.number,
          height: PropTypes.number,
        }),
      })
    ),
  }

  static defaultProps = {
    heading: '',
    text: '',
    solutions: [],
  }

  render() {
    const { heading, text, solutions } = this.props

    return (
      <Segment>
        <Leader className={s.leader} centered heading={heading} text={text} />
        <Container>
          <Row>
            {solutions.map(solution => (
              <div className={s.solutionsList__card} key={solution.heading}>
                <SolutionCard
                  heading={solution.heading}
                  link={solution.link}
                  text={solution.text}
                  image={solution.image}
                />
              </div>
            ))}
          </Row>
        </Container>
      </Segment>
    )
  }
}
