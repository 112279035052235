import React from 'react'
import PropTypes from 'prop-types'
import ContentfulRichText from 'components/contentful-rich-text'

import Image from 'components/image'

import s from './Item.scss'

const Item = ({ quote, cite, image }) => {
  return (
    <div className={s.testimonialsItem}>
      <div className={s.testimonialsItem__content}>
        {quote && (
          <blockquote className={s.testimonialsItem__quote}>
            {quote}
          </blockquote>
        )}
        {cite && <span className={s.testimonialsItem__cite}>{cite}</span>}
      </div>
      {image?.file?.url && (
        <div className={s.testimonialsItem__image}>
          <Image
            src={`${image.file.url}?w=${450}`}
            src2x={`${image.file.url}?w=${450 * 2}`}
            width={image.file.details.image.width}
            height={image.file.details.image.height}
          />
        </div>
      )}
    </div>
  )
}

Item.propTypes = {
  quote: PropTypes.string,
  cite: PropTypes.string,
  image: PropTypes.object,
}

export default Item
