import { graphql } from 'gatsby'
import Home from 'routes/home/Home'

export default Home

export const query = graphql`
  # Use this fragment to fetch the URL and alt text of a Contentful image
  fragment image on ContentfulAsset {
    file {
      url
      contentType
      details {
        image {
          width
          height
        }
      }
    }
    title
    description
  }

  fragment headingAndText on ContentfulTitleAndBody {
    heading: title
    text: body {
      text: body
    }
    # tooltip
  }

  query Home {
    page: contentfulPageHome {
      pageTitle
      metaDescription
      metaImage {
        ...image
      }
      heroHeading: heroTitle
      heroText: heroBody {
        text: heroBody
      }
      heroImage {
        ...image
      }
      heroVideoFile {
        file {
          url
        }
      }
      heroVideoBg {
        file {
          url
        }
      }
      heroTextAlignment
      heroCtaText
      heroCtaUrl
      solutionsHeading: solutionsTitle
      solutionsText: solutionsBody {
        text: solutionsBody
      }
      solutionLinks {
        heading: pageTitle
        slug
        text: previewDescription {
          text: previewDescription
        }
        image: previewImage {
          ...image
        }
      }
      animatedList {
        ...moduleList
      }
      featuredHeading: featuredTitle
      featuredImage {
        ...image
      }
      featuredText: featuredBody {
        text: featuredBody
      }
      featuredCtaText
      featuredCtaUrl
      testimonials {
        ...moduleList
      }
      proxyLoveButton
      proxyLoveButtonActive
      proxyLoveBody {
        json
      }
      proxyLoveTestimonials {
        ...moduleList
      }
      modules {
        ...moduleList
      }
    }
  }
`
