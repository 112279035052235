import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import LazyLoad from 'react-lazyload'

import extractMeta from 'utils/extract-meta'
import { withUIContext } from 'context/ui'
import SEO from 'components/seo'
import GreyArea from 'components/grey-area'
import Hero from 'components/home-hero'
import ContentfulRichText from 'components/contentful-rich-text'
import Modules from 'containers/modules'
import ProxyLoveBanner from 'components/proxy-love-banner'

import SolutionsList from './components/solutions-list'
import IdentitySignal from './components/identity-signal'
import Awards from './components/awards'

class Home extends PureComponent {
  static propTypes = {
    data: PropTypes.object.isRequired,
    ui: PropTypes.object,
  }

  componentDidMount() {
    this.props.ui.setNavTheme('light')
  }

  componentWillUnmount() {
    this.props.ui.setNavTheme('dark')
  }

  render() {
    const { page, love } = this.props.data
    const { pathname } = this.props.location

    return (
      <Fragment>
        <SEO {...extractMeta(page)} article />
        <Hero
          heading={page.heroHeading}
          text={page?.heroText?.text}
          videoFile={page?.heroVideoFile?.file?.url}
          videoBg={page?.heroVideoBg?.file?.url}
          videoPlayCount="once"
          textAlignment={page?.heroTextAlignment}
          poster={page?.heroImage?.file?.url}
          cta={
            page?.heroCtaText && !page?.heroVideoFile?.file?.url
              ? {
                  text: page?.heroCtaText,
                  url: page?.heroCtaUrl,
                  color: 'white',
                }
              : null
          }
          theme="light"
          themeMobile="light"
          customContentClass="homeContent"
        />
        <LazyLoad once>
          <SolutionsList
            heading={page.solutionsHeading}
            text={page.solutionsText?.text}
            solutions={
              page.solutionLinks &&
              page.solutionLinks.map(solution => ({
                heading: solution.heading,
                link: `/solutions/${solution.slug}`,
                text: solution.text?.text,
                image: {
                  src: solution?.image?.file?.url,
                  alt: solution?.image?.description,
                  width: solution?.image?.file?.details?.image?.width,
                  height: solution.image?.file?.details?.image?.height,
                },
              }))
            }
          />
        </LazyLoad>

        <LazyLoad once offset={500}>
          <GreyArea>
            <Modules list={[page.animatedList]} />
            <IdentitySignal
              heading={page.featuredHeading}
              image={{
                src: page.featuredImage?.file?.url,
                alt: page.featuredImage?.description,
                width: page.featuredImage?.file?.details?.image?.width,
                height: page.featuredImage?.file?.details?.image?.height,
              }}
              text={page.featuredText?.text}
              ctaText={page.featuredCtaText}
              ctaUrl={page.featuredCtaUrl}
            />
            <Awards />
          </GreyArea>
        </LazyLoad>

        <LazyLoad once offset={500}>
          <ProxyLoveBanner
            testimonials={page.proxyLoveTestimonials}
            body={page.proxyLoveBody}
            button={page.proxyLoveButton}
            buttonActive={page.proxyLoveButtonActive}
          />
        </LazyLoad>

        <LazyLoad once offset={500}>
          <Modules list={page.modules} />
        </LazyLoad>
      </Fragment>
    )
  }
}

export default withUIContext(Home)
