import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'

import { Container } from 'components/layout'

import s from './Awards.scss'

const query = graphql`
  query AwardsQuery {
    data: contentfulPageHome {
      awardsTitle
      awards {
        label
        title
        image {
          ...image
        }
      }
    }
  }
`

const Awards = () => (
  <StaticQuery query={query}>
    {({data}) => (
      <div className={s.wrapper}>
        <Container>
          {data.awardsTitle && <div className={s.title}>{data.awardsTitle}</div>}
          <div className={s.awards}>
            {data.awards?.map(({ label, title, image }) => (
              <div key={label+title} className={s.award}>
                {image?.file && <img className={s.awardImage} alt={label} src={image.file.url}/>}
                {title && <div className={s.awardsTitle}>{title}</div>}
              </div>
            ))}
          </div>
        </Container>
      </div>
    )}
  </StaticQuery>
)

export default Awards
